<template>
  <div class="product-report px-4">
    <b-card class="page-card">
      <div slot="header">
        <b-row>
          <b-col cols="12">
            <div class="page-header-left">
              <div class="pr-1 header-icon">
                <i class="cuis-terminal card-icon-font"></i>
              </div>
              <div>
                <h5 class="mb-0">
                  <b>FIN Technographics</b>
                </h5>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <div v-if="!banks">
        <content-placeholders>
          <content-placeholders-text :lines="2"></content-placeholders-text>
        </content-placeholders>
        <hr />
        <content-placeholders>
          <content-placeholders-img
            class="table-holder"
          ></content-placeholders-img>
        </content-placeholders>
      </div>
      <div v-else>
        <div class="page-sub-title">
          <div>
            <div class="d-flex align-items-center">
              <header-logo
                logoType="vendor"
                :logoId="vendorId"
                :logoUrl="vendorLogoUrl"
                class="justify-content-center pb-2"
              ></header-logo>
              <div>
                <h5>{{ vendor }} - {{ product }}</h5>
                <h6>Industry Solution: {{ bankService }}</h6>
              </div>
            </div>
          </div>
          <div>
            <span class="title-icon">
              <i class="fa fa-university fa-3x"></i>
            </span>
            <div class="inline-display">
              <v-wait for="applyingFilter">
                <template slot="waiting">
                  <i class="fa fa-spinner fa-spin"></i>
                </template>
                <h4 class="mb-0" v-if="resultCount === 0">
                  <b>0</b>
                </h4>
                <h4 class="mb-0" v-else>
                  <b>{{ resultCount | numberFormat }}</b>
                </h4>
              </v-wait>
              <h6>Matching {{ resultCount | pluralize('Institution') }}</h6>
            </div>
          </div>
        </div>
        <hr class="header-divide" />
        <div>
          <b-row>
            <b-col cols="12">
              <b-table
                striped
                hover
                responsive="md"
                :items="banks"
                :fields="fields"
              >
                <template v-slot:cell(city)="data">{{
                  data.value.toLowerCase() | titleCase
                }}</template>
                <template v-slot:cell(is_bank)="data">{{
                  data.value === true ? 'Bank' : 'Credit Union'
                }}</template>
                <template v-slot:cell(assets)="data">{{
                  data.value | numberFormat
                }}</template>
                <template
                  v-slot:cell(table-caption)="data"
                  v-if="this.total === 0"
                >
                  <div class="text-center">
                    <i>No data found ...</i>
                  </div>
                </template>
              </b-table>
              <BlockUI :blockMsg="blockMsg" v-if="blockUI"></BlockUI>
            </b-col>
          </b-row>
          <div>
            <br />
            <b-card-group deck>
              <b-card
                :class="[
                  'purchase-card alert alert-info',
                  { 'hide-with-space': emptyResultSet }
                ]"
              >
                <v-wait for="updatingPriceDetails">
                  <template slot="waiting">
                    <content-placeholders-heading />
                    <content-placeholders-text
                      :lines="7"
                    ></content-placeholders-text>
                  </template>
                  <b-row>
                    <b-col cols="2">
                      <span class="title-icon">
                        <i class="fa fa-download fa-4x"></i>
                      </span>
                    </b-col>
                    <b-col cols="5">
                      <div>
                        <h2 class="mb-0">
                          <b>${{ totalAmount | numberFormat }}</b>
                          <small>
                            <sup>00</sup>
                          </small>
                        </h2>
                      </div>
                      <div>Buy &amp; Download Now</div>
                    </b-col>
                    <b-col cols="5" style="width: 10.5em;color: white;">
                      <button
                        v-b-modal
                        class="btn btn-success"
                        @click="handlePurchase()"
                        :disabled="disablePurchase"
                      >
                        Purchase Now
                      </button>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="2"></b-col>
                    <b-col cols="5">
                      <b-row class="price-box">
                        <table class="table-borderless">
                          <thead>
                            <tr>
                              <th>Price Details</th>
                              <th></th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Number of FIs</td>
                              <td class="text-right">{{ resultCount }}</td>
                              <td></td>
                            </tr>
                            <tr v-if="isMinimumOrder">
                              <td class="text-no-wrap pr-4">Minimum Order</td>
                              <td class="text-right">$500.00</td>
                              <td></td>
                            </tr>
                            <tr v-if="!isMinimumOrder">
                              <td>Cost / FI</td>
                              <td class="text-right">${{ pricePerFI }}</td>
                              <td></td>
                            </tr>
                            <tr v-if="!isMinimumOrder">
                              <td>Sub - Total</td>
                              <td class="text-right">
                                ${{ priceWithoutDiscounts.toFixed(2) }}
                              </td>
                              <td></td>
                            </tr>
                            <tr v-if="tokens !== 0">
                              <td>Tokens Used</td>
                              <td class="text-right">{{ tokensBeingUsed }}</td>
                              <td class="text-no-wrap">
                                of {{ tokens }} total
                              </td>
                            </tr>
                            <tr class="strong">
                              <td>Total Price</td>
                              <td class="text-right">
                                ${{ parseFloat(totalAmount).toFixed(2) }}
                              </td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </b-row>
                    </b-col>
                    <b-col cols="5"></b-col>
                  </b-row>
                </v-wait>
              </b-card>
              <b-card class="std-border mb-0 firmographics-filter">
                <b-form @submit="onSubmit" @reset="onReset">
                  <b-row>
                    <b-col cols="7">
                      <h4 class="title-item mb-0">
                        <b>
                          <i class="fa fa-filter fa-lg"></i>
                          <span id="filterInfo">
                            Firmographics Filter
                            <i class="icon-info icons small-line-icon"></i>
                          </span>
                        </b>
                      </h4>
                      <b-popover :target="'filterInfo'" triggers="hover focus"
                        >Identify your competitive sweet spot of financial
                        institutions at the intersection of firmographics,
                        technographics and performographics. This unprecedented
                        combination of traditional FI characteristics,
                        technologies deployed (or absent) and performance
                        challenges enhances your account-based marketing (ABM)
                        by identifying those institutions where your messaging
                        will resonate.</b-popover
                      >
                      <span class="filter-help"
                        >Use filters below to focus your list</span
                      >
                    </b-col>
                    <b-col cols="5 text-right">
                      <b-button type="reset" variant="secondary" class="mr-1"
                        >Reset</b-button
                      >
                      <b-button type="submit" variant="primary"
                        >Apply Filter</b-button
                      >
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col sm="4" class="total_asset_label"
                      >Total Assets(000s):</b-col
                    >
                    <b-col sm="4">
                      <b-form-group>
                        <b-form-input
                          type="number"
                          id="asset-min"
                          v-model="form.assetMin"
                          placeholder="Min"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col sm="4">
                      <b-form-group>
                        <b-form-input
                          type="number"
                          id="asset-max"
                          v-model="form.assetMax"
                          placeholder="Max"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="4">FI Type:</b-col>
                    <b-col sm="8">
                      <b-form-group>
                        <b-row>
                          <b-col cols="12">
                            <b-form-checkbox-group
                              id="fi_types"
                              v-model="form.fiType"
                              name="fi_types"
                              :options="filterOptions"
                            ></b-form-checkbox-group>
                          </b-col>
                        </b-row>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="4" class="geography_label">Geography:</b-col>
                    <b-col sm="8">
                      <multiselect
                        v-model="form.geography"
                        placeholder="Select State"
                        :options="states"
                        :multiple="true"
                        class="avoid-overlay"
                      ></multiselect>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card>
            </b-card-group>
          </div>
        </div>
      </div>
    </b-card>
    <OrderDetails
      :stage="orderStage"
      :clientToken="clientToken"
      :downloadUrl="downloadUrl"
      :show="showModal"
      :totalRecords="filterResult"
      :amount="totalAmount"
      :loading="loading"
      :tokens="tokens"
      :tokensBeingUsed="tokensBeingUsed"
      @pay="payForOrder"
      @closeModal="closeModal"
      @createOrder="createOrder"
    ></OrderDetails>
    <UserAuthenticationModal
      :show="showAuthModal"
      @closeAuthModal="closeAuthModal"
      @loginSuccess="loginSuccess"
    ></UserAuthenticationModal>
  </div>
</template>

<script>
// global
import { mapGetters, mapState } from 'vuex'
import { BTErrorMessage } from '@/utilities'
// ui components
import Multiselect from 'vue-multiselect'
import UserAuthenticationModal from '@/modules/core/components/UserAuthenticationModal'
import HeaderLogo from '@/modules/core/components/HeaderLogo'

const OrderDetails = () => import('../OrderDetails')
const BlockUI = () => import('../BlockUI')

export default {
  components: {
    OrderDetails,
    BlockUI,
    HeaderLogo,
    Multiselect,
    UserAuthenticationModal
  },
  created() {
    this.$store.dispatch('marketShareReport/getStatesList')
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.productId = vm.$route.params.id
      vm.vendorId = vm.$route.query.vendor_id
      vm.solutionId = vm.$route.query.bank_service_id
      vm.$http
        .get('api/bank_service_products/' + vm.productId, {
          params: { vendor_id: vm.vendorId, bank_service_id: vm.solutionId },
          handleErrors: true
        })
        .then(response => {
          vm.banks = response.data.banks
          vm.product = response.data.product
          vm.vendor = response.data.vendor
          vm.total = response.data.total
          vm.filterResult = response.data.total
          vm.bankService = response.data.bank_service
          vm.totalAmount = response.data.total_price
          vm.encryptedPrice = response.data.encrypted_price
          vm.minimumPrice = response.data.minimum_price
          vm.priceWithoutDiscounts = response.data.price_without_discounts
          vm.tokens = parseInt(response.data.tokens)
          document.title = 'FIN Technographics'
          if (!vm.skipFilter()) {
            vm.blockUI = true
            vm.setFilterData()
            vm.submitFilter()
          }
        })
    })
  },
  data() {
    return {
      form: {
        assetMin: null,
        assetMax: null,
        fiType: ['bank', 'cu'],
        geography: []
      },
      blockUI: false,
      product: '',
      vendor: '',
      bankService: '',
      banks: null,
      total: 0,
      filterResult: null,
      priceWithoutDiscounts: 0,
      minimumPrice: 0,
      tokens: 0,
      fields: [
        { key: 'name', sortable: false, label: 'Name' },
        {
          key: 'address',
          sortable: false,
          label: 'Street Address / Main Office'
        },
        { key: 'city', sortable: false, label: 'City' },
        { key: 'state', sortable: false, label: 'State' },
        { key: 'is_bank', sortable: false, label: 'FI Type' },
        {
          key: 'assets',
          sortable: false,
          label: 'Assets',
          class: 'text-right'
        },
        {
          key: 'branches',
          sortable: false,
          label: 'Branches',
          class: 'text-right'
        },
        { key: 'website_url', sortable: false, label: 'Domain' }
      ],
      showModal: false,
      orderId: null,
      clientToken: null,
      orderStage: 'summary',
      loading: false,
      downloadUrl: null,
      fiTypes: { 1: ['bank'], 2: ['cu'] },
      totalAmount: 0,
      encryptedPrice: null,
      showAuthModal: false
    }
  },
  computed: {
    ...mapGetters('Authentication', ['currentUserEmail']),
    ...mapGetters('Ability', [
      'canPurchase',
      'isFinQueryAvailable',
      'isMbcAvailable'
    ]),
    ...mapState('marketShareReport', ['states']),
    canShowMbc() {
      return this.isFinQueryAvailable && this.isMbcAvailable
    },
    filterOptions() {
      return this.canShowMbc
        ? {
            bank: 'Banks',
            cu: 'Credit Unions',
            mbc: 'MBC'
          }
        : {
            bank: 'Banks',
            cu: 'Credit Unions'
          }
    },
    disablePurchase() {
      if (!this.canPurchase) {
        return true
      }
      return this.total <= 0 || this.resultCount <= 0
    },
    resultCount: function() {
      return this.filterResult !== null ? this.filterResult : this.total
    },
    pricePerFI() {
      return (this.priceWithoutDiscounts / this.resultCount).toFixed(2)
    },
    tokensBeingUsed() {
      if (this.priceWithoutDiscounts == 0 && this.minimumPrice == 0) {
        return 0
      } else if (this.priceWithoutDiscounts < this.minimumPrice) {
        return Math.min(this.minimumPrice, this.tokens)
      } else if (this.priceWithoutDiscounts > this.minimumPrice) {
        return Math.min(this.tokens, this.priceWithoutDiscounts)
      } else {
        return this.tokens
      }
    },
    isMinimumOrder() {
      return this.priceWithoutDiscounts < this.minimumPrice
    },
    blockMsg() {
      let noRecord = 'No records found.'
      let noFilter = 'Filter details are available on purchase.'
      return this.resultCount > 0 ? noFilter : noRecord
    },
    emptyResultSet() {
      return this.resultCount <= 0
    },
    vendorLogoUrl() {
      return `https://finapps-vendor-logos.s3-us-west-2.amazonaws.com/logo_${this.vendorId}.png`
    }
  },
  methods: {
    submitFilter() {
      this.$wait.start('applyingFilter')
      this.orderStage = 'summary'
      let data = {
        vendor_id: this.$route.query.vendor_id,
        asset_min: this.form.assetMin,
        asset_max: this.form.assetMax,
        fi_types: this.form.fiType,
        geography: this.form.geography,
        bank_service_id: this.$route.query.bank_service_id
      }
      return this.$http
        .post(
          'api/bank_service_products/' +
            this.$route.params.id +
            '/firmographic_filter',
          data,
          {
            handleErrors: true
          }
        )
        .then(res => {
          this.filterResult = res.data.total
          this.totalAmount = res.data.total_price
          this.encryptedPrice = res.data.encrypted_price
          this.priceWithoutDiscounts = res.data.price_without_discounts
          this.tokens = parseInt(res.data.tokens)
          this.$wait.end('applyingFilter')
        })
    },
    skipFilter() {
      return [
        this.$route.query.asset_min,
        this.$route.query.asset_max,
        this.$route.query.fi_type
      ].every(val => val === undefined)
    },
    setFilterData() {
      this.form.assetMin = this.$route.query.asset_min
      this.form.assetMax = this.$route.query.asset_max
      this.form.fiType = this.fiTypes[this.$route.query.fi_type] || [
        'bank',
        'cu'
      ]
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.blockUI = true
      this.submitFilter()
    },
    onReset(evt) {
      evt.preventDefault()
      this.form.assetMin = null
      this.form.assetMax = null
      this.form.fiType = ['bank', 'cu']
      this.form.geography = []
      this.submitFilter().then(() => {
        this.blockUI = false
      })
    },
    handlePurchase: function() {
      if (this.currentUserEmail) {
        this.trackDownload()
        this.showModal = true
      } else {
        this.showAuthModal = true
      }
    },
    trackDownload() {
      this.$ahoy.track('download', {
        product_id: this.productId,
        vendor_id: this.vendorId,
        solution_id: this.solutionId,
        report_type: 'FIN Technographics'
      })
    },
    closeModal: function() {
      this.showModal = false
      this.orderStage = 'summary'
      this.updatePriceDetails()
    },
    updatePriceDetails() {
      this.$wait.start('updatingPriceDetails')
      let data = {
        vendor_id: this.$route.query.vendor_id,
        asset_min: this.form.assetMin,
        asset_max: this.form.assetMax,
        fi_types: this.form.fiType,
        geography: this.form.geography,
        bank_service_id: this.$route.query.bank_service_id
      }
      return this.$http
        .post(
          'api/bank_service_products/' +
            this.$route.params.id +
            '/firmographic_filter',
          data,
          {
            handleErrors: true
          }
        )
        .then(res => {
          this.filterResult = res.data.total
          this.totalAmount = res.data.total_price
          this.encryptedPrice = res.data.encrypted_price
          this.priceWithoutDiscounts = res.data.price_without_discounts
          this.tokens = parseInt(res.data.tokens)
          this.$wait.end('updatingPriceDetails')
        })
    },
    createOrder: function() {
      this.loading = true
      this.$http
        .post(
          '/api/checkouts',
          {
            record_count: this.resultCount,
            amount: this.totalAmount,
            product_id: this.productId,
            vendor_id: this.vendorId,
            encrypted_price: this.encryptedPrice,
            filters: {
              asset_min: this.form.assetMin,
              asset_max: this.form.assetMax,
              fi_types: this.form.fiType,
              bank_service_id: this.$route.query.bank_service_id,
              geography: this.form.geography
            }
          },
          {
            handleErrors: true
          }
        )
        .then(res => {
          this.loading = false
          if (res.data.ordered) {
            this.downloadUrl = res.data.url
            this.orderStage = 'confirmation'
          } else {
            this.clientToken = res.data.client_token
            this.orderId = res.data.order_id
            this.orderStage = 'payment'
          }
        })
    },
    payForOrder: function(payload) {
      let data = new window.FormData()
      data.append('order_id', this.orderId)
      data.append('payment_method_nonce', payload.nonce)

      this.loading = true
      this.$http
        .post('/api/checkouts/complete', data, {
          handleErrors: true
        })
        .then(res => {
          this.loading = false
          if (res.data.order_success) {
            this.downloadUrl = res.data.url
            this.orderStage = 'confirmation'
            this.tokens = res.data.tokens
          } else {
            this.closeModal()
            this.$toasted.show(BTErrorMessage, {
              icon: 'chain-broken',
              type: 'error'
            })
          }
        })
    },
    loginSuccess() {
      this.closeAuthModal()
      this.blockUI = true
      this.submitFilter()
    },
    closeAuthModal() {
      this.showAuthModal = false
      this.showModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
.product-report {
  .purchase-card {
    .card-body {
      &.alert {
        padding: 1em 0;
      }
      padding: 0;
      button {
        color: white;
        font-size: 1.3em;
      }
      .title-icon {
        color: gray;
      }
      .col-5 {
        color: initial;
      }
    }
  }
  .firmographics-filter {
    .total_asset_label {
      margin-top: 8px;
    }
    .geography_label {
      margin-top: 3px;
    }
  }

  .purchase-link {
    color: #20a8d8;

    &:hover {
      color: #167495;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .table-holder {
    height: 30rem;
  }

  .purchase-modal {
    .list-group-item {
      border: none;
      padding: 0.5rem 1.25rem;
    }
  }

  .title-icon {
    padding-right: 0.5rem;
    vertical-align: text-bottom;
  }

  .header-divide {
    margin: 0.5rem 0;
  }

  .page-sub-title,
  .table-caption {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .inline-display {
      display: inline-block;
      .fa-spinner {
        font-size: 1.5em;
      }
    }

    .caption-content {
      width: 80%;
      padding-right: 1rem;
    }
  }
}
.filter-help {
  font-size: 0.8rem;
  margin-left: 1.7rem;
}

.loading-container {
  position: fixed;
}

.price-box {
  padding: 1rem 0 0 1rem;
  width: 16rem;

  table {
    width: 16rem;
  }

  .strong {
    font-weight: bolder;
  }
}

.hide-with-space {
  visibility: hidden;
}
.avoid-overlay {
  z-index: 1000;
}
</style>
